
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  /* font-size: 18px; */
  /* background: #fff; */
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("../../images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

/* .swiper-slide::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
} */

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  

}

.bgSlider{
    /* background: #0f67be; */
    background-image: url("../../images/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    
}
